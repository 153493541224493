import 'mapbox-gl/dist/mapbox-gl.css';

const mapboxAccessToken = 'pk.eyJ1IjoibWFwMjN0cmF2ZWwiLCJhIjoiY202aHNrOGNiMDB2dDJpc2FmNnRrMDhvNSJ9.mG12nf2DP1TWsBa2P_0j6w';
const mapboxStyleId = 'mapbox://styles/map23travel/cm106d9a801a601qo19ehandv';
export const savedStyleUrl = localStorage.getItem('selectedMapStyle') || mapboxStyleId; // Если нет, используем стиль по умолчанию


export const layerUrl = 'mapbox://map23travel.4eqycuzb';
export const sourceLayer = 'features-b5mxon';

export const mapConfig = {
    accessToken: mapboxAccessToken,
    styleId: savedStyleUrl,
    center: [98.3923, 7.8804], // Пхукет
    zoom: 15,
    containerId: 'map'
};
