// Функция для авторизации пользователя
import {API_URL} from "./config";

export async function loginUser(login, password) {
    try {
        const response = await fetch(`${API_URL}/api/auth/token/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ login, password })
        });

        if (response.ok) {
            const data = await response.json();
            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, errors: errorData };
        }
    } catch (error) {
        console.error('Ошибка при авторизации:', error);
        return { success: false, errors: { detail: 'Произошла ошибка при авторизации.' } };
    }
}

// Функция для регистрации нового пользователя
export async function registerUser(email, username, firstName, lastName, password) {
    try {
        const response = await fetch(`${API_URL}/api/users/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, username, first_name: firstName, last_name: lastName, password })
        });

        if (response.ok) {
            const data = await response.json();
            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, errors: errorData };
        }
    } catch (error) {
        console.error('Ошибка при регистрации:', error);
        return { success: false, errors: { detail: 'Произошла ошибка при регистрации.' } };
    }
}

export async function refreshAccessToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        console.warn('Нет refresh-токена в localStorage');
        return null;
    }

    try {
        const response = await fetch(`${API_URL}/api/auth/jwt/refresh/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refresh: refreshToken })
        });

        if (!response.ok) {
            console.warn('Refresh-токен недействителен или просрочен');
            return null;
        }

        const data = await response.json(); // ожидаем { access: '...', ...(optionally refresh) }
        localStorage.setItem('accessToken', data.access);

        if (data.refresh) {
            localStorage.setItem('refreshToken', data.refresh);
        }

        return data.access;
    } catch (error) {
        console.error('Ошибка при обновлении access-токена:', error);
        return null;
    }
}


export async function getUserProfile() {
    try {
        const response = await apiRequest(`${API_URL}/api/users/me/`, {
            method: 'GET'
        });

        if (response.ok) {
            const data = await response.json();
            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, errors: errorData };
        }
    } catch (error) {
        console.error('Ошибка при получении профиля:', error);
        return { success: false, errors: { detail: 'Произошла ошибка при получении профиля.' } };
    }
}

// Функция для обновления информации о профиле пользователя
export async function updateUserProfile(updatedData) {
    try {
        const response = await apiRequest(`${API_URL}/api/users/me/`, {
            method: 'PUT',
            body: JSON.stringify(updatedData)
        });

        if (response.ok) {
            const data = await response.json();
            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, errors: errorData };
        }
    } catch (error) {
        console.error('Ошибка при обновлении профиля:', error);
        return { success: false, errors: { detail: 'Произошла ошибка при обновлении профиля.' } };
    }
}
