// Функция для генерации уникального ID для новых точек
import {map} from "../index";

export function generateUniqueId() {
    return Date.now() + Math.floor(Math.random() * 1000);
}

export function dmsToDecimal(dms) {
    const parts = dms.match(/(\d+)[°\s](\d+)'(\d+(\.\d+)?)"(N|S|E|W)/);
    if (!parts) return null;

    let degrees = parseFloat(parts[1]);
    const minutes = parseFloat(parts[2]);
    const seconds = parseFloat(parts[3]);
    const direction = parts[5];

    let decimal = degrees + (minutes / 60) + (seconds / 3600);


    // Учитываем направление
    if (direction === 'S' || direction === 'W') {
        decimal *= -1;
    }
    return decimal; // Возвращаем число
}

export function isPointClosedNow(workingHours, date) {
    if (!workingHours) return false;

    let parsed;
    try {
        parsed = JSON.parse(workingHours);
    } catch (e) {
        console.warn('Ошибка парсинга', e);
        return false;
    }

    // Определяем, какой сейчас день недели (0=Sunday, 1=Monday, ...)
    const dayOfWeek = date.getDay();
    const dayKey = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'][dayOfWeek];

    const schedule = parsed[dayKey];
    if (!schedule) {
        // если расписания нет, считаем точку закрытой
        return true;
    }

    // Если весь день закрыто
    if (schedule.is_closed) {
        return true;
    }
    // Если круглосуточно
    if (schedule.is_24) {
        return false;
    }

    // Иначе проверяем интервалы open_times/close_times
    const nowMinutes = date.getHours() * 60 + date.getMinutes();
    const openArr = schedule.open_times || [];
    const closeArr = schedule.close_times || [];

    // По умолчанию считаем, что закрыто
    let closed = true;

    for (let i = 0; i < openArr.length; i++) {
        const [oH, oM] = openArr[i].split(':').map(Number);
        const [cH, cM] = closeArr[i].split(':').map(Number);

        // Переводим "часы:минуты" в минуты с полуночи
        const openMin  = oH * 60 + oM;
        const closeMin = cH * 60 + cM;

        // Случай 1: расписание в рамках одного дня (например, 12:00 - 23:00)
        if (openMin < closeMin) {
            if (nowMinutes >= openMin && nowMinutes < closeMin) {
                closed = false;
                break; // можно прервать цикл, т.к. уже нашли интервал, где точка открыта
            }
        }
        // Случай 2: расписание переваливает за полночь (например, 12:00 - 03:00)
        else if (openMin > closeMin) {
            // тогда открыто либо с openMin до 24:00 (1440), либо с 0 до closeMin
            if (nowMinutes >= openMin || nowMinutes < closeMin) {
                closed = false;
                break;
            }
        }
        // (Если openMin === closeMin, обычно трактуют как 24 часа,
        //  но у вас есть schedule.is_24 для круглосуточных)
    }

    return closed;
}



export function updateOpenCloseStates() {
    const now = new Date();

    const features = map.querySourceFeatures('places', {
        sourceLayer: 'mylayer'
    });

    features.forEach(feature => {
        const wh = feature.properties.working_hours;
        const isClosed = isPointClosedNow(wh, now);

        // Если feature.id = undefined, setFeatureState не сработает
        if (feature.id != null) {
            map.setFeatureState(
                { source: 'places', sourceLayer: 'mylayer', id: feature.id },
                { closed: isClosed }
            );
        }
    });
}