import {getMarkerTypes} from './api/markerTypes';
import mapboxgl from 'mapbox-gl';
import {API_URL, isLocalBackend} from "./api/config";
import {gifMarkersById, map} from "../index";
import {popup, addPopupToMap, removePopup, showEditForm} from './interactive';


// Функция для загрузки и добавления изображений маркера
// TODO mb del?
function loadAndAddImage(url, imageName, map, callback) {
    map.loadImage(url, (error, image) => {
        if (error) {
            console.error(`Ошибка загрузки изображения маркера ${imageName}:`, error);
            throw error;
        }
        map.addImage(imageName, image);
        if (callback) callback();  // Вызываем коллбек, если он есть
    });
}

function buildTextColorExpression(subcatIcons) {
    // Начинаем с ["match", ["get","subcategory"]]
    const expression = ['match', ['get', 'subcategory']];

    for (const subcat of subcatIcons) {
        expression.push(subcat.name);                        // значение подкатегории
        expression.push(subcat.text_color || '#FFFFFF');     // цвет
    }

    // Цвет по умолчанию
    expression.push('#FFFFFF');
    return expression;
}

function getGifScale(zoom) {
    const minZoom = 5, maxZoom = 18;
    const minScale = 0.1, maxScale = 0.35;
    const zClamped = Math.max(minZoom, Math.min(maxZoom, zoom));
    const t = (zClamped - minZoom) / (maxZoom - minZoom);
    return minScale + t * (maxScale - minScale);
}

export async function loadAndRegisterSubcategoryImages(map, mapStyleId) {
    try {
        const url = `${API_URL}/api/styles/${mapStyleId}/icons/`;
        const response = await apiRequest(url);
        const subcatIcons = await response.json();

        // 1. Регистрируем иконки в Mapbox
        for (const subcat of subcatIcons) {
            if (!subcat.name) continue;
            const imgUrl = subcat.logo?.logo;
            if (!imgUrl) continue;

            // Загружаем картинку и добавляем как map-иконку
            await new Promise((resolve) => {
                map.loadImage(imgUrl, (error, image) => {
                    if (error) {
                        console.error(`Ошибка загрузки: ${imgUrl}`, error);
                        return resolve();
                    }
                    map.addImage(subcat.name, image);
                    resolve();
                });
            });
        }

        // 2. Строим match-выражение для text_color
        const textColorExpression = buildTextColorExpression(subcatIcons);
        console.log("textColorExpression:", JSON.stringify(textColorExpression, null, 2));

        // 3. Применяем это выражение к слоям, которые показывают текст
        // Предположим, что слои для текста: 'places-name-layer' и 'places-name-shadow-layer'
        map.setPaintProperty('places', 'text-color', textColorExpression);
        map.setPaintProperty('places', 'icon-opacity', [
            'case',
            ['boolean', ['feature-state', 'closed'], false],
            0.7,
            1
        ]);
    } catch (error) {
        console.error('Ошибка при загрузке SubcategoryIcons:', error);
    }
}

export function createGifMarkers() {
    const features = map.querySourceFeatures('places', { sourceLayer: 'mylayer' });

    features.forEach(feature => {
        const props = feature.properties || {};
        const coords = feature.geometry.coordinates;
        const featId = feature.id;
        if (!featId) return;

        // Проверяем, marker_type это ссылка на WebM-видео?
        if (typeof props.marker_type === 'string' && props.marker_type.endsWith('.webm')) {
            // Если маркер уже создан — пропускаем
            if (gifMarkersById[featId]) return;

            // Создаём video элемент
            const el = document.createElement('video');
            el.src = props.marker_type;
            el.style.width = '35px';
            el.style.height = '35px';
            el.style.transformOrigin = 'center center';
            el.autoplay = true; // Видео будет запускаться автоматически
            el.loop = true; // Зацикливаем видео
            el.muted = true; // Отключаем звук

            const currentZoom = map.getZoom();
            const initialScale = getGifScale(currentZoom);
            el.style.transform = `scale(${initialScale})`;

            // Создаём Marker
            const marker = new mapboxgl.Marker({ element: el, anchor: 'center' })
                .setLngLat(coords)
                .addTo(map);

            // Добавляем обработчики событий
            const popupId = props.id;
            const popupName = props.name || 'No name';
            const popupDesc = props.description || '';

            el.addEventListener('click', (e) => {
                e.stopPropagation(); // Не даём клику уйти на карту
                showEditForm(feature);
            });

            el.addEventListener('mouseenter', () => {
                el.style.cursor = 'pointer';
                addPopupToMap(
                    map,
                    popup,
                    coords,
                    popupId,
                    popupName,
                    popupDesc
                );
            });

            el.addEventListener('mouseleave', () => {
                el.style.cursor = '';
                removePopup(popup);
            });

            // Запоминаем, чтобы не дублировать
            gifMarkersById[featId] = { marker, el };
        }
    });
}


export function initGifMarkerScaling() {
    map.on('zoom', () => {
        const z = map.getZoom();
        const scale = getGifScale(z);
        for (const featId in gifMarkersById) {
            const { el } = gifMarkersById[featId];
            if (!el) continue;
            el.style.transform = `scale(${scale})`;
        }
    });
}