import {API_ADMIN_TOKEN, API_URL} from './config';
import mapboxgl from 'mapbox-gl';
import {map} from '../../index'
import {showEditForm} from "../interactive";
import {allowAddingPoints} from "../openEditPoint";
import {setupMapClickEvent} from "../events";
import {renderChildPointModal} from "../point_form/polygons";


/**
 * Функция для загрузки всех точек
 * @returns {Promise<Object>} - GeoJSON FeatureCollection
 */
export async function fetchAllPoints() {

    const url = `${API_URL}/api/feature/collection/`;

    try {
        const response = await apiRequest(url, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error(`Ошибка сети: ${response.status} ${response.statusText}`);
        }

        const points = await response.json(); // Массив фич

        return {
            type: 'FeatureCollection',
            features: points,
        };
    } catch (error) {
        console.error('Ошибка при загрузке точек:', error);
        return {
            type: 'FeatureCollection',
            features: [],
        };
    }
}

export async function getDefaultWorkingHours() {
    try {
        const response = await apiRequest(`${API_URL}/api/working_hours/default/`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Ошибка при получении часов работы по умолчанию');
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        return null;
    }
}


export function clearPolygon() {
    if (map.getLayer('polygon-layer')) {
        map.removeLayer('polygon-layer');
    }
    if (map.getSource('polygon')) {
        map.removeSource('polygon');
    }
    // Очистка дочерних точек
    if (map.getLayer('polygon-children-icons-layer')) {
        map.removeLayer('polygon-children-icons-layer');
    }
    if (map.getLayer('polygon-children-labels-layer')) {
        map.removeLayer('polygon-children-labels-layer');
    }
    if (map.getSource('polygon-children')) {
        map.removeSource('polygon-children');
    }

    // Удаляем попап, если он открыт
    if (childrenPopup) {
        childrenPopup.remove();
        childrenPopup = null;
    }

    if (map.getLayer('trails-layer')) {
        map.removeLayer('trails-layer');
    }
    if (map.getSource('trails')) {
        map.removeSource('trails');
    }
}

let childrenPopup = null;

// Объявляем обработчики событий в области видимости модуля
let childrenMouseEnterHandler = null;
let childrenMouseLeaveHandler = null;
export let childrenClickHandler = null;


export function displayPolygon(polygonCoordinates, polygonChildren, parentFeature) {


    // Отображение полигона
    if (polygonCoordinates && polygonCoordinates.length > 0) {
        const polygonGeoJSON = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: polygonCoordinates
            }
        };

        if (map.getSource('polygon')) {
            map.getSource('polygon').setData(polygonGeoJSON);
        } else {
            map.addSource('polygon', {
                type: 'geojson',
                data: polygonGeoJSON
            });

            map.addLayer({
                id: 'polygon-layer',
                type: 'fill',
                source: 'polygon',
                layout: {},
                paint: {
                    'fill-color': 'rgba(19,12,213,0.51)',
                    'fill-opacity': 0.4,
                    'fill-emissive-strength': 1 // Maximum emissive strength for full brightness
                }
            });

            map.addLayer({
                id: 'polygon-border',
                type: 'line',
                source: 'polygon',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#ff0000', // Красный цвет границы
                    'line-width': 2, // Ширина линии
                    'line-dasharray': [2, 4] // Параметры для создания пунктирной линии
                }
            });
        }
    }

    // Добавление дочерних точек
    if (polygonChildren && polygonChildren.length > 0) {
        // Добавляем свойство parentId к каждой дочерней точке
        const childrenGeoJSON = {
            type: 'FeatureCollection',
            features: polygonChildren.map(child => ({
                ...child,
                properties: {
                    ...child.properties,
                    parentId: parentFeature.properties.id // Добавляем parentId
                }
            }))
        };

        if (map.getSource('polygon-children')) {
            map.getSource('polygon-children').setData(childrenGeoJSON);
            console.log('polygon-children-source обновлён');
        } else {
            map.addSource('polygon-children', {
                type: 'geojson',
                data: childrenGeoJSON
            });

            // Слой для иконок дочерних точек
            map.addLayer({
                id: 'polygon-children-icons-layer',
                type: 'symbol',
                source: 'polygon-children',
                layout: {
                    'icon-image': ['get', 'marker_type'],
                    'icon-size': 1,
                    'icon-allow-overlap': true
                }
            }, 'places');

            // Слой для названий дочерних точек
            map.addLayer({
                id: 'polygon-children-labels-layer',
                type: 'symbol',
                source: 'polygon-children',
                layout: {
                    'text-field': ['get', 'name'],
                    'text-font': ['HelveticaNeueCyr Heavy'],
                    'text-size': 12,
                    'text-offset': [0, 1.5], // Смещение текста под иконкой
                    'text-anchor': 'top'
                },
                paint: {
                    'text-color': '#333333',
                    'text-halo-color': '#ffffff',
                    'text-halo-width': 1
                }
            });

            // Добавляем обработчики событий для интерактивности
            addPolygonChildrenInteractions(parentFeature);
        }
    }
}

export function displayTrails(trailCoordinates, parentFeature) {
    // Отображение троп
    if (trailCoordinates && trailCoordinates.length > 0) {
        const trailsGeoJSON = {
            type: 'FeatureCollection',
            features: trailCoordinates.map(coords => ({
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: coords
                },
                properties: {
                    parentId: parentFeature.properties.id
                }
            }))
        };

        if (map.getSource('trails')) {
            map.getSource('trails').setData(trailsGeoJSON);
        } else {
            map.addSource('trails', {
                type: 'geojson',
                data: trailsGeoJSON
            });

            map.addLayer({
                id: 'trails-layer',
                type: 'line',
                source: 'trails',
                layout: {},
                paint: {
                    'line-color': '#79ff00', // Цвет линии
                    'line-width': 3
                }
            });
        }
    }
}



function addPolygonChildrenInteractions(parentFeature) {
    // Сначала удаляем существующие обработчики, если они были добавлены ранее
    if (childrenMouseEnterHandler) {
        map.off('mouseenter', 'polygon-children-icons-layer', childrenMouseEnterHandler);
    }
    if (childrenMouseLeaveHandler) {
        map.off('mouseleave', 'polygon-children-icons-layer', childrenMouseLeaveHandler);
    }
    if (childrenClickHandler) {
        map.off('click', 'polygon-children-icons-layer', childrenClickHandler);
    }

    // Обработчик наведения курсора на дочернюю точку
    childrenMouseEnterHandler = function (e) {
        childrenClickHandler = true;
        setupMapClickEvent(map);
        map.getCanvas().style.cursor = 'pointer';

        const feature = e.features[0];
        const coordinates = feature.geometry.coordinates.slice();
        const properties = feature.properties;

        // Корректировка координат
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Создаем или обновляем попап
        if (!childrenPopup) {
            childrenPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });
        }

        const popupContent = `
        <div class="popup-content">
            <div class="card" style="width: 18rem; max-width: 100%;">
                <div class="card-body">
                    <h5 class="card-title">ID: ${properties.id}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">${properties.name}</h6>
                </div>
            </div>
        </div>
    `;

        childrenPopup.setLngLat(coordinates).setHTML(popupContent).addTo(map);
    };

    // Обработчик ухода курсора с дочерней точки
    childrenMouseLeaveHandler = function () {
        map.getCanvas().style.cursor = '';
        if (childrenPopup) {
            childrenPopup.remove();
            childrenPopup = null;
        }
        childrenClickHandler = false;
        setupMapClickEvent(map);
    };

    // Обработчик клика по дочерней точке
    childrenClickHandler = function (e) {
        const feature = e.features[0];
        const parentId = feature.properties.parentId;
        // Функция открытия окна редактирования дочерней точки
        renderChildPointModal(parentFeature, feature, null, () => {
            // Дополнительные действия после закрытия модального окна, если необходимо
        });
        childrenClickHandler = false;
        setupMapClickEvent(map);
    };

    // Назначаем обработчики событий
    map.on('mouseenter', 'polygon-children-icons-layer', childrenMouseEnterHandler);
    map.on('mouseleave', 'polygon-children-icons-layer', childrenMouseLeaveHandler);
    map.on('click', 'polygon-children-icons-layer', childrenClickHandler);

}


export async function populatePoint(pointId) {
    try {
        const response = await apiRequest(`${API_URL}/api/points/${pointId}/`);

        if (!response.ok) {
            throw new Error('Ошибка при загрузке точки: ' + response.statusText);
        }

        const point = await response.json();

        if (point.polygon && point.polygon.coordinates) {
            const polygonCoordinates = point.polygon.coordinates;
            const polygonChildren = point.polygon_children || [];
            displayPolygon(polygonCoordinates, polygonChildren, point);
        }

        if (point.trails && point.trails.coordinates && point.trails.coordinates.length > 0) {
            const trailCoordinates = point.trails.coordinates;
            displayTrails(trailCoordinates, point);
        }

        return point;

    } catch (error) {
        console.error('Ошибка загрузки точки:', error);
    }
}


// Функция для создания Point'а
export async function createPoint(pointData) {
    const url = `${API_URL}/api/points/`;


    // Создаем объект точки, который нужно отправить
    const pointPayload = {
        properties: {
            name: pointData.name,
            description: pointData.description,
            short_description: pointData.shortDescription,
            json_description: pointData.jsonDescription,
            address: pointData.address,
            category: pointData.category,
            subcategory: pointData.subcategory,
            contact_info: {
                phone_numbers: pointData.contactInfo.phoneNumbers || [],
                whatsapp_urls: pointData.contactInfo.whatsappUrls || [],
                viber_urls: pointData.contactInfo.viberUrls || [],
                telegram_urls: pointData.contactInfo.telegramUrls || [],
                instagram_urls: pointData.contactInfo.instagramUrls || [],
                facebook_urls: pointData.contactInfo.facebookUrls || [],
                emails: pointData.contactInfo.email || [],
                menu_url: pointData.contactInfo.menu || [],
                tripadvisor_urls: pointData.contactInfo.tripadvisorUrls || [],
                line_urls: pointData.contactInfo.lineUrls || [],
                websites: pointData.contactInfo.websites || [],
            },
            working_hours: pointData.workingHours,
            price_list: pointData.priceList,
            tags: pointData.tags,
            zoom: pointData.zoom,
            google_maps_url: pointData.googleMapsUrls,
            json_menu: pointData.jsonMenu,
        },
        geometry: {
            type: "Point",
            coordinates: pointData.coordinates,
        }
    };

    if (pointData.markerType !== 'custom-marker') {
        pointPayload.properties.marker_type = pointData.markerType;
    }

    if (pointData.polygon) {
        pointPayload.polygon = {
            type: "Polygon",
            coordinates: pointData.polygon
        };
    }

    if (pointData.trails) {
        pointPayload.trails = {
            type: "MultiLineString",
            coordinates: pointData.trails.coordinates
        };
    }


    try {
        // Шаг 1: Создаем точку
        const response = await apiRequest(url, {
            method: 'POST',
            body: JSON.stringify(pointPayload),
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }

        const responseData = await response.json();
        const pointId = responseData.properties.id;

        // Шаг 2: Загружаем логотип, если он есть
        if (pointData.logo) {
            const logoFormData = new FormData();
            logoFormData.append('logo', pointData.logo);

            const logoResponse = await apiRequest(`${API_URL}/api/points/${pointId}/logo/`, {
                method: 'PATCH',
                body: logoFormData,
            });

            if (!logoResponse.ok) {
                throw new Error('Ошибка при загрузке логотипа: ' + logoResponse.statusText);
            }

            const logoResponseData = await logoResponse.json();
            console.log('Логотип успешно загружен:', logoResponseData);
        }

        // Шаг 3: Загружаем изображения, если они есть
        if (pointData.images.length > 0) {
            const imagesFormData = new FormData();
            pointData.images.forEach((image) => {
                imagesFormData.append('images', image); // добавляем файл изображения
            });

            const imagesResponse = await apiRequest(`${API_URL}/api/points/${pointId}/images/`, {
                method: 'POST',
                body: imagesFormData,
            });

            if (!imagesResponse.ok) {
                throw new Error('Ошибка при загрузке изображений: ' + imagesResponse.statusText);
            }

            const imagesResponseData = await imagesResponse.json();
            console.log('Изображения успешно загружены:', imagesResponseData);
        }
    } catch (error) {
        console.error('Ошибка при создании точки:', error);
    }
}


/**
 * Функция для обновления точки
 * @param {number|string} id - ID точки для обновления
 * @param {Object} pointData - Данные точки для обновления
 * @returns {Promise<Object>} - Обновленная точка
 */
export async function updatePoint(id, pointData) {
    const url = `${API_URL}/api/points/${id}/`;

    const pointPayload = {
        properties: {
            name: pointData.name,
            description: pointData.description,
            short_description: pointData.shortDescription,
            json_description: pointData.jsonDescription,
            address: pointData.address,
            marker_type: pointData.markerType,
            category: pointData.category,
            subcategory: pointData.subcategory,
            google_maps_url: pointData.googleMapsUrls,
            json_menu: pointData.jsonMenu,
            contact_info: {
                phone_numbers: pointData.contactInfo.phoneNumbers || [],
                whatsapp_urls: pointData.contactInfo.whatsappUrls || [],
                viber_urls: pointData.contactInfo.viberUrls || [],
                telegram_urls: pointData.contactInfo.telegramUrls || [],
                instagram_urls: pointData.contactInfo.instagramUrls || [],
                facebook_urls: pointData.contactInfo.facebookUrls || [],
                emails: pointData.contactInfo.email || [],
                menu_url: pointData.contactInfo.menu || [],
                tripadvisor_urls: pointData.contactInfo.tripadvisorUrls || [],
                line_urls: pointData.contactInfo.lineUrls || [],
                websites: pointData.contactInfo.websites || [],
            },
            working_hours: pointData.workingHours,
            price_list: pointData.priceList,
            tags: pointData.tags,
            zoom: pointData.zoom,
        },
        geometry: {
            type: "Point",
            coordinates: pointData.coordinates,
        }
    };

    if (pointData.polygon) {
        pointPayload.polygon = {
            type: "Polygon",
            coordinates: pointData.polygon
        };
    }

    if (pointData.trails) {
        pointPayload.trails = {
            type: "MultiLineString",
            coordinates: pointData.trails.coordinates
        };
    }

    try {
        // Шаг 1: Обновляем данные точки
        const response = await apiRequest(url, {
            method: 'PATCH',
            body: JSON.stringify(pointPayload),
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }

        const responseData = await response.json();
        const pointId = responseData.properties.id;

        if (pointData.logo) {
            const logoFormData = new FormData();
            logoFormData.append('logo', pointData.logo);

            const logoResponse = await apiRequest(`${API_URL}/api/points/${pointId}/logo/`, {
                method: 'PATCH',
                body: logoFormData,
            });

            if (!logoResponse.ok) {
                throw new Error('Ошибка при загрузке логотипа: ' + logoResponse.statusText);
            }

            const logoResponseData = await logoResponse.json();
            console.log('Логотип успешно загружен:', logoResponseData);
        }

        if (pointData.images.length > 0) {
            const imagesFormData = new FormData();
            pointData.images.forEach((image) => {
                imagesFormData.append('images', image); // добавляем файл изображения
            });

            const imagesResponse = await apiRequest(`${API_URL}/api/points/${pointId}/images/`, {
                method: 'POST',
                body: imagesFormData,
            });

            if (!imagesResponse.ok) {
                throw new Error('Ошибка при загрузке изображений: ' + imagesResponse.statusText);
            }

            const imagesResponseData = await imagesResponse.json();
            console.log('Изображения успешно загружены:', imagesResponseData);
        }

        return responseData; // Возвращаем обновленную точку

    } catch (error) {
        console.error('Ошибка при обновлении точки:', error);
        throw error;
    }
}


/**
 * Функция для удаления точки
 * @param {number|string} id - ID точки для удаления
 */
export async function deletePoint(id) {

    const url = `${API_URL}/api/points/${id}/`;

    try {
        const response = await apiRequest(url, {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }
        console.log(`Точка с ID ${id} удалена`);
    } catch (error) {
        console.error(`Ошибка удаления Точки ${id}:`, error);
        throw error;
    }
}