import {iconList, placesData, setCurrentFeature} from './../index';
import {MARKER_TYPE_ZOOM_DEFAULT} from "./data";
import {popup, addPopupToMap, showEditForm} from "./interactive";
import mapboxgl from "mapbox-gl";

export const placeLayerId = 'places';

function getFontName() {
    const fontName = localStorage.getItem('selectedFontName');
    if (fontName) {
        return [fontName];
    }
    return ["HelveticaNeueCyr Heavy"];
}

export function addPlacesLayer(map, placesData) {
    map.loadImage(
        'https://tap-maptravel.s3.ap-southeast-2.amazonaws.com/media/svgs/circle/%D0%9A%D1%80%D1%83%D0%B3_rdr.png',
        (error, image) => {
            if (error) {
                console.error("Ошибка загрузки иконки:", error);
                return;
            }

            if (!map.hasImage('my_dot_icon')) {
                map.addImage('my_dot_icon', image, { sdf: false });
            }
            if (!map.getSource('places')) {
                map.addSource('places', {
                    type: 'vector',
                    // tiles: ['http://localhost:8080/data/tiles/{z}/{x}/{y}.pbf'],
                    tiles: ['https://map-travel.net/tilesets/data/tiles/{z}/{x}/{y}.pbf'],
                    minzoom: 0,
                    maxzoom: 20
                });
            }

            if (!map.getLayer(placeLayerId)) {
                map.addLayer({
                    id: placeLayerId,
                    type: 'symbol',
                    source: 'places',
                    'source-layer': 'mylayer',

                    layout: {
                        "icon-image": [
                            "let", "myThreshold", 500,
                            [
                                "case",
                                ["<", ["to-number", ["coalesce", ["get","min_dist"], 0]], ["var","myThreshold"]],
                                "my_dot_icon",
                                ["get","subcategory"]
                            ]
                        ],
                        'text-allow-overlap': false,
                        'icon-allow-overlap': true,
                        'text-optional': true,

                        'icon-size': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            5, [
                                '*',
                                ['coalesce', ['get', 'zoom'], 1],
                                ['/', 0.1, ['coalesce', ['get', 'zoom'], 1]]
                            ],
                            18, [
                                '*',
                                ['coalesce', ['get', 'zoom'], 1],
                                0.35
                            ]
                        ],

                        'text-field': ['get', 'name'],
                        'text-font': getFontName(),
                        'text-size': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            5, 3,
                            18, 14
                        ],
                        'text-offset': [
                            'interpolate', ['linear'], ['zoom'],
                            5, ['literal', [0, 1.05]],
                            18, ['literal', [0, 1.55]]
                        ],
                        'text-anchor': 'top',
                    },
                    paint: {
                        'text-color': '#ffffff',
                        'text-halo-color': 'rgba(0,0,0,0.75)',
                        'text-halo-width': 2,
                        'text-halo-blur': 0.5
                    }
                });
            }
})}

// Дальше вы не используете updateGifMarkers, так как gif-places слой уже есть.
// Если хотите фильтровать по рейтингу, делаете это извне через map.setFilter().
export function updatePlacesLayerData(map, newData) {
    const source = map.getSource(placeLayerId);
    // processFeatures(newData.features);
    if (source) {
        source.setData(newData);
    } else {
        console.error(`Источник данных для слоя ${placeLayerId} не найден`);
        addPlacesLayer(map, newData);
    }
}
